import React from 'react';
import { motion } from 'framer-motion';
import TestimonialSlider from '../components/TestimonialSlider';
import { testimonials } from '../data/testimonials';
import SocialLinks from '../components/SocialLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLightbulb, 
  faUsers, 
  faPuzzlePiece, 
  faShieldCheck, 
  faArrowsRotate, 
  faComments
} from '@fortawesome/pro-light-svg-icons';

const About: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-[52rem] mx-auto"
    >
      <motion.div
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2 }}
        className="bg-white rounded-xl shadow-lg overflow-visible relative"
      >
        {/* Social Links */}
        <SocialLinks />
        <div className="p-10 md:p-14">
          <motion.h1 
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-3xl font-bold text-gray-900 mb-6"
          >
            About Me
          </motion.h1>
          
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.4 }}
            className="prose prose-lg max-w-none mb-8"
          >
            <p className="text-gray-600 text-xl ">
              Highly motivated Product Designer and Principal UX Engineer with strong Full Stack development expertise. 
              Excel at conceptualizing and crafting user-centered products that have a transformative impact. 
              Deeply versed in creating responsive and intuitive design systems, employing a keen eye for layout, 
              typography, and color to invoke joy and empowerment.
            </p>
          </motion.div>

          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.3 }}
            className="mb-8"
          >
            <h3 className="text-2xl font-bold mb-4">Soft Skills</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {[
                { skill: 'Resourceful', icon: faLightbulb, color: 'bg-[#F52549]/10 text-[#F52549]' },
                { skill: 'Collaborative', icon: faUsers, color: 'bg-[#638fd8]/10 text-[#638fd8]' },
                { skill: 'Problem Solver', icon: faPuzzlePiece, color: 'bg-[#4b5563]/10 text-[#4b5563]' },
                { skill: 'Detail-Oriented', icon: faShieldCheck, color: 'bg-[#F52549]/10 text-[#F52549]' },
                { skill: 'Adaptable', icon: faArrowsRotate, color: 'bg-[#638fd8]/10 text-[#638fd8]' },
                { skill: 'Strong Communicator', icon: faComments, color: 'bg-[#4b5563]/10 text-[#4b5563]' }
              ].map((item, index) => (
                <div 
                  key={index}
                  className={`${item.color} rounded-lg p-3 flex items-center justify-center gap-2 font-medium shadow-sm hover:shadow-md transition-all duration-200`}
                >
                  <FontAwesomeIcon 
                    icon={item.icon} 
                    className="w-5 h-5" 
                  />
                  {item.skill}
                </div>
              ))}
            </div>
          </motion.div>

          <section className="mb-8">
            <h3 className="text-2xl font-bold mb-2">What People Say</h3>
            <p className="text-gray-600 mb-8 text-xl">
              Throughout my journey, I've had the privilege of collaborating with incredible teams and clients. 
              Here are some of their thoughts about our work together.
            </p>
            <TestimonialSlider testimonials={testimonials} autoPlayInterval={5000} />
          </section>
        </div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6 }}
          className="grid grid-cols-2"
        >
          <a 
            href="/contact" 
            className="w-full py-4 text-sm font-semibold text-white bg-[#638fd8] hover:bg-[#7B9AD2] transition-colors duration-200 text-center uppercase tracking-wider rounded-bl-xl"
          >
            Hire Me
          </a>
          <a 
            href="/images/Suzanne-Brenner-Senior-Frontend-Engineer.pdf" 
            className="w-full py-4 text-sm font-semibold text-white bg-[#4b5563] hover:bg-[#374151] transition-colors duration-200 text-center uppercase tracking-wider rounded-br-xl"
            download="Suzanne-Brenner-Senior-Frontend-Engineer.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Resume
          </a>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default About; 