import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faChevronRight,
  faCode,
  faPaintBrush,
  faUsers
} from '@fortawesome/pro-light-svg-icons';
import SocialLinks from '../components/SocialLinks';

const Home: React.FC = () => {
  // Generate random particles
  const particles = Array.from({ length: 150 }, (_, i) => ({
    id: i,
    size: Math.random() * 3 + 1,
    left: Math.random() * 100,
    animationDuration: Math.random() * 15 + 8,
    animationDelay: Math.random() * 10,
    opacity: Math.random() * 0.4 + 0.1
  }));

  return (
    <div className="min-h-screen relative overflow-hidden">
      {/* CSS Particles Background */}
      <div className="fixed inset-0 pointer-events-none z-0">
        {particles.map(particle => (
          <div
            key={particle.id}
            className="absolute rounded-full bg-[#89ABE3] animate-float"
            style={{
              width: `${particle.size}px`,
              height: `${particle.size}px`,
              left: `${particle.left}%`,
              opacity: particle.opacity,
              animation: `float ${particle.animationDuration}s infinite linear`,
              animationDelay: `${particle.animationDelay}s`
            }}
          />
        ))}
      </div>

      {/* Background Image Container */}
      <div className="fixed inset-0 pointer-events-none z-0">
        <div 
          className="absolute inset-0 bg-hero bg-cover bg-center opacity-50"
        />
      </div>

      {/* Content Container */}
      <div className="relative z-10 min-h-[calc(100vh-6rem)] -mt-[50px] md:-mt-[50px] mt-[20px] pb-4 flex items-center justify-center px-8 lg:px-24">
        <div className="w-full max-w-[52rem]">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-xl shadow-lg overflow-visible relative"
          >
            {/* Social Links */}
            <SocialLinks />
            
            <div className="p-10 md:p-14">
              {/* Name Header */}
              <h1
                className="text-5xl md:text-5xl text-center text-[#F52549] mb-6 font-semibold"
              >
                Ambi Suzanne Brenner
              </h1>

              <motion.p
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 2.5 }}
                className="text-xl text-gray-600 mb-10 text-center"
              >
                Passionate Full Stack Developer and UX/UI Engineer, crafting digital experiences 
                that blend creativity with technical excellence.
              </motion.p>
              
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.8 }}
                className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10"
              >
                <div className="p-6 rounded-xl bg-[#89ABE3]/5 border border-[#89ABE3]/10">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="w-12 h-12 rounded-lg bg-[#89ABE3]/10 flex items-center justify-center">
                      <FontAwesomeIcon icon={faCode} className="text-[#89ABE3] text-2xl" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900">Full Stack Development</h3>
                  </div>
                  <p className="text-gray-600">Building robust and scalable applications from front to back.</p>
                </div>

                <div className="p-6 rounded-xl bg-[#EA738D]/5 border border-[#EA738D]/10">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="w-12 h-12 rounded-lg bg-[#EA738D]/10 flex items-center justify-center">
                      <FontAwesomeIcon icon={faPaintBrush} className="text-[#EA738D] text-2xl" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900">UX/UI Engineering</h3>
                  </div>
                  <p className="text-gray-600">Creating intuitive and engaging user experiences.</p>
                </div>

                <div className="p-6 rounded-xl bg-[#4b5563]/5 border border-[#4b5563]/10">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="w-12 h-12 rounded-lg bg-[#4b5563]/10 flex items-center justify-center">
                      <FontAwesomeIcon icon={faUsers} className="text-[#4b5563] text-2xl" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900">Technical Leadership</h3>
                  </div>
                  <p className="text-gray-600">Guiding teams and projects to successful outcomes.</p>
                </div>
              </motion.div>

              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 1 }}
                className="flex justify-end"
              >
                <a 
                  href="/about" 
                  className="group inline-flex items-center gap-2 text-lg font-medium text-[#F52549] hover:text-[#d65d7a] transition-colors"
                >
                  What people say
                  <FontAwesomeIcon 
                    icon={faChevronRight} 
                    className="transition-transform group-hover:translate-x-1"
                  />
                </a>
              </motion.div>
            </div>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.6 }}
              className="grid grid-cols-2"
            >
              <a 
                href="/portfolio" 
                className="w-full py-4 text-sm font-semibold text-white bg-[#638fd8] hover:bg-[#7B9AD2] transition-colors duration-200 text-center uppercase tracking-wider rounded-bl-xl"
              >
                View My Work
              </a>
              <a 
                href="/contact" 
                className="w-full py-4 text-sm font-semibold text-white bg-[#4b5563] hover:bg-[#374151] transition-colors duration-200 text-center uppercase tracking-wider rounded-br-xl"
              >
                Get in Touch
              </a>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Home;