import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { certifications } from '../data/certifications';
import ImageModal from '../components/ImageModal';

const Certs: React.FC = () => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="max-w-[52rem] mx-auto"
            >
                <motion.div
                    initial={{ y: -20 }}
                    animate={{ y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="bg-white rounded-xl shadow-lg overflow-hidden"
                >
                    <div className="p-10 md:p-14">
                        <motion.h1
                            initial={{ y: -20 }}
                            animate={{ y: 0 }}
                            transition={{ delay: 0.2 }}
                            className="text-3xl font-bold text-gray-900 mb-6 text-start"
                        >
                            Certifications & Learning
                        </motion.h1>
                        
                        <motion.p
                            initial={{ y: -20 }}
                            animate={{ y: 0 }}
                            transition={{ delay: 0.3 }}
                            className="text-gray-600 mb-10 text-start text-xl"
                        >
                            A collection of my professional certifications and completed learning courses, 
                            showcasing my commitment to continuous learning and skill development.
                        </motion.p>

                        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-2">
                            {certifications.map((cert, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 + index * 0.1 }}
                                    className="group relative bg-gray-50 rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 flex flex-col h-full"
                                >
                                    {/* Certificate Preview */}
                                    <div className="aspect-[4/3] overflow-hidden">
                                        <img 
                                            src={cert.imageUrl} 
                                            alt={cert.title}
                                            className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500 ease-out"
                                        />
                                    </div>

                                    {/* Content */}
                                    <div className="p-6 pb-0 flex-grow">
                                        <h3 className="text-xl font-bold text-gray-900 mb-2 group-hover:text-[#638fd8] transition-colors">
                                            {cert.title}
                                        </h3>
                                        <p className="text-sm text-gray-600 mb-1">
                                            {cert.issuer}
                                        </p>
                                        <p className="text-sm text-gray-500 mb-4">
                                            {cert.date}
                                        </p>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => setSelectedImage(cert.imageUrl)}
                                            className="w-full px-4 py-3 text-sm font-semibold text-white bg-[#638fd8] hover:bg-[#7B9AD2] transition-colors duration-300 rounded-b-xl flex items-center justify-center"
                                        >
                                            View Certificate
                                            <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                        </button>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </motion.div>
            </motion.div>

            <ImageModal
                isOpen={!!selectedImage}
                imageUrl={selectedImage || ''}
                onClose={() => setSelectedImage(null)}
            />
        </>
    );
};

export default Certs;