import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const Navigation: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path: string) => location.pathname === path;

  const navItems = [
    { path: '/about', label: 'About' },
    { path: '/portfolio', label: 'Portfolio' },
    { path: '/resume', label: 'Resume' },
    { path: '/certs', label: 'Certs' },
    { path: '/contact', label: 'Contact' }
  ];

  return (
    <header className="sticky top-0 inset-x-0 z-50 w-full bg-white border-b border-gray-100">
      <nav className="relative max-w-[52rem] w-full mx-auto" aria-label="Global">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <div className="w-8 h-8 rounded-lg bg-white border border-[#F52549]/20 flex items-center justify-center transition-all duration-300 hover:bg-[#F52549] hover:scale-110 group shadow-sm">
              <i className="far fa-smile text-[#F52549] text-lg transition-colors duration-300 group-hover:text-white"></i>
            </div>
            <span className="text-xl font-semibold text-gray-900">Ambi Suzanne</span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden sm:flex items-center justify-end space-x-1">
            {navItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`relative px-4 py-2 text-md font-medium rounded-lg transition-all ${
                  isActive(item.path)
                    ? 'text-[#F52549] bg-[#F52549]/5'
                    : 'text-gray-600 hover:text-[#F52549] hover:bg-gray-50'
                }`}
              >
                {item.label}
              </Link>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <div className="sm:hidden">
            <button
              type="button"
              className="p-2 rounded-lg text-gray-600 hover:bg-gray-50 hover:text-[#F52549] transition-colors"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              aria-label="Toggle navigation"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                {isMobileMenuOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        <motion.div
          initial={false}
          animate={{ height: isMobileMenuOpen ? 'auto' : 0 }}
          className="sm:hidden overflow-hidden"
        >
          <div className="py-2 space-y-1">
            {navItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`block px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                  isActive(item.path)
                    ? 'text-[#F52549] bg-[#F52549]/5'
                    : 'text-gray-600 hover:text-[#F52549] hover:bg-gray-50'
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </motion.div>
      </nav>
    </header>
  );
};

export default Navigation; 