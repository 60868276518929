import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const SocialLinks: React.FC = () => {
  const socialLinks = [
    { icon: faGithub, url: 'https://github.com/ambisuzanne', label: 'GitHub' },
    { icon: faLinkedin, url: 'https://www.linkedin.com/in/ambi-suzanne-brenner/', label: 'LinkedIn' },
    { icon: faGlobe, url: 'https://power3designs.com', label: 'Power3 Designs' }
  ];

  return (
    <div className="absolute -right-6 top-1/2 -translate-y-1/2 hidden lg:flex flex-col gap-6 z-30">
      {socialLinks.map((link, index) => (
        <a
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="group relative"
        >
          <div className="w-12 h-12 rounded-full bg-white shadow-lg flex items-center justify-center hover:bg-[#F52549] transition-colors duration-200 border border-[#F52549]">
            <FontAwesomeIcon 
              icon={link.icon} 
              className="text-[#F52549] text-xl group-hover:text-white transition-colors duration-200"
            />
          </div>
          <span className="absolute left-full top-1/2 -translate-y-1/2 mr-4 bg-white px-3 py-1 rounded-md text-sm font-medium text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
            {link.label}
          </span>
        </a>
      ))}
    </div>
  );
};

export default SocialLinks; 