import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { projects } from '../data/projects';
import PasswordProtected from '../components/PasswordProtected';

const Portfolio: React.FC = () => {
  const PortfolioContent = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-[52rem] mx-auto"
    >
      <motion.div
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2 }}
        className="bg-white rounded-xl shadow-lg overflow-hidden"
      >
        <div className="p-10 md:p-14">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2 }}
            className="mb-12"
          >
            <motion.h1 
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-3xl font-bold text-gray-900 mb-6"
            >
              My Portfolio
            </motion.h1>
            
            <motion.p
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.3 }}
              className="text-gray-600 text-xl"
            >
              Welcome to my portfolio! Here you'll find a collection of projects I've poured my heart into, 
              full-stack applications, code, and user experience designs. Each project tells a story of innovation, 
              problem-solving, and the joy of bringing ideas to life.
            </motion.p>
          </motion.div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
          >
            {projects.map((project, index) => (
              <Link 
                to={`/portfolio/${project.id}`}
                key={index}
                className="block group relative bg-gray-50 rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 flex flex-col h-full"
              >
                {/* Certificate Preview */}
                <div className="aspect-[4/3] overflow-hidden">
                  <img 
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500 ease-out" 
                    src={project.image} 
                    alt={project.title}
                  />
                </div>

                {/* Content */}
                <div className="p-6 pb-0 flex-grow">
                  <h3 className="text-xl font-bold text-gray-900 mb-2 group-hover:text-[#638fd8] transition-colors">
                    {project.title}
                  </h3>
                  <p className="text-sm text-gray-600 mb-4 line-clamp-2">
                    {project.description}
                  </p>

                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.technologies.map((tech, idx) => (
                      <span
                        key={idx}
                        className="py-1 px-2 bg-gray-100 text-gray-700 text-xs rounded-lg"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>

                <div>
                  <button
                    className="w-full px-4 py-3 text-sm font-semibold text-white bg-[#638fd8] hover:bg-[#7B9AD2] transition-colors duration-300 rounded-b-xl flex items-center justify-center"
                  >
                    View Details
                    <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </div>
              </Link>
            ))}
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );

  return (
    <PasswordProtected>
      <PortfolioContent />
    </PasswordProtected>
  );
};

export default Portfolio; 