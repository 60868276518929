import React, { useState } from 'react';
import { motion } from 'framer-motion';
import SocialLinks from '../components/SocialLinks';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const apiUrl = process.env.NODE_ENV === 'production' 
        ? '/api/contact'
        : 'http://localhost:3001/api/contact';

      console.log('Attempting to send request to:', apiUrl);
      console.log('With data:', formData);

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Response data:', data);

      if (data.success) {
        setStatus('success');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        console.error('Server error:', data.error);
        setStatus('error');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('error');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex items-center justify-center min-h-[calc(100vh-6rem)] -mt-[50px] md:-mt-[50px] mt-[20px] pb-4"
    >
      <div className="w-full max-w-[52rem] mx-auto">
        <motion.div
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white rounded-xl shadow-lg relative"
        >
          <div className="absolute right-0 top-1/2 -translate-y-1/2 z-10">
            <SocialLinks />
          </div>
          <div className="p-10 md:p-14">
            <motion.h1 
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-3xl font-bold text-gray-900 mb-6"
            >
              Get in Touch
            </motion.h1>
            
            <div className="mb-8">
              <p className="text-gray-600 text-xl">
                I'm always interested in hearing about new projects and opportunities.
                Feel free to reach out through the form below or connect with me on social media.
              </p>
            </div>

            <motion.div
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="max-w-md mx-auto"
            >
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <div className="relative">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-[#89ABE3] focus:ring-[#89ABE3] disabled:opacity-50 disabled:pointer-events-none"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-[#89ABE3] focus:ring-[#89ABE3] disabled:opacity-50 disabled:pointer-events-none"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div>
                  <div className="relative">
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-[#89ABE3] focus:ring-[#89ABE3] disabled:opacity-50 disabled:pointer-events-none"
                      placeholder="Subject"
                    />
                  </div>
                </div>

                <div>
                  <div className="relative">
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      rows={4}
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-[#89ABE3] focus:ring-[#89ABE3] disabled:opacity-50 disabled:pointer-events-none"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>

                {status === 'success' && (
                  <div className="p-4 bg-green-100 text-green-700 rounded-lg text-sm">
                    Message sent successfully!
                  </div>
                )}

                {status === 'error' && (
                  <div className="p-4 bg-red-100 text-red-700 rounded-lg text-sm">
                    Failed to send message. Please try again.
                  </div>
                )}
              </form>
            </motion.div>
          </div>

          <div>
            <motion.button
              whileTap={{ scale: 0.98 }}
              onClick={(e) => handleSubmit(e as any)}
              disabled={status === 'sending'}
              className={`w-full py-4 text-sm font-semibold text-white rounded-b-xl ${
                status === 'sending' 
                  ? 'bg-gray-400' 
                  : 'bg-[#638fd8] hover:bg-[#7B9AD2]'
              } transition-colors duration-200 uppercase tracking-wider`}
            >
              {status === 'sending' ? 'Sending...' : 'Send Message'}
            </motion.button>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Contact; 