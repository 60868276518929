export interface Testimonial {
  name: string;
  company: string;
  text: string;
}

export const testimonials: Testimonial[] = [
  {
    name: "D. Brewer",
    company: "InSightPD",
    text: "Good Work! Thank you for updating our testing server and production sites. Really good work."
  },
  {
    name: "Ethan",
    company: "NuevoCloud",
    text: "Our documentation looks awesome! Technical and well put together."
  },
  {
    name: "Rita C.",
    company: "TruSightVR",
    text: "Awesome working with you Suzanne. My site is more than I imagined!"
  },
  {
    name: "Michael S.",
    company: "VP of Engineering",
    text: "Exceptional attention to detail and a true professional. The UI/UX improvements transformed our application."
  },
  {
    name: "Sarah P.",
    company: "Head of Design",
    text: "Working with Suzanne was a game-changer for our project. Her technical expertise and design sensibility are outstanding."
  }
]; 