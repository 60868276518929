import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-[#282d39]">
      <Navigation />
      <main className="flex-1 w-full max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 relative z-0">
        {children}
      </main>
      <div className="h-24 md:hidden"></div>
      <Footer />
    </div>
  );
};

export default Layout; 