import React from 'react';

const Footer: React.FC = () => {
  return (
      <footer className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 py-4 md:hidden z-50">
        <div className="max-w-[52rem] w-full mx-auto">
          <div className="flex justify-end">
            <a
                className="inline-flex justify-center items-center gap-2 px-4 h-10 text-center text-gray-500 hover:text-[#F52549] hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-[#EA738D] focus:ring-offset-2 focus:ring-offset-white transition"
                href="https://github.com/ambisuzanne"
                target="_blank"
                rel="noopener noreferrer"
            >
              <i className="text-xl fab fa-github"></i>
              <span>GitHub</span>
            </a>
            <a
                className="inline-flex justify-center items-center gap-2 px-4 h-10 text-center text-gray-500 hover:text-[#F52549] hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-[#EA738D] focus:ring-offset-2 focus:ring-offset-white transition ml-3"
                href="https://www.linkedin.com/in/ambi-suzanne-brenner/"
                target="_blank"
                rel="noopener noreferrer"
            >
              <i className="text-xl fab fa-linkedin"></i>
              <span>LinkedIn</span>
            </a>
            <a
                className="inline-flex justify-center items-center gap-2 px-4 h-10 text-center text-gray-500 hover:text-[#F52549] hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-[#EA738D] focus:ring-offset-2 focus:ring-offset-white transition ml-3"
                href="https://www.power3designs.com"
                target="_blank"
                rel="noopener noreferrer"
            >
              <i className="text-xl fas fa-globe"></i>
              <span>Power3Designs</span>
            </a>
          </div>
        </div>
      </footer>
  );
};

export default Footer;