export interface Project {
  id: string;
  title: string;
  description: string;
  technologies: string[];
  image: string;
  link?: string;
  userStory?: string;
  gallery?: string[];
  features?: string[];
  challenges?: string[];
  solutions?: string[];
}

export const projects: Project[] = [
  {
    id: "power3designs-marketing",
    title: "Power3Designs Marketing Website",
    description: "Full-stack web development service for Power3Designs, a web design and development company.",
    technologies: ["React", "Node.js", "AWS", "Stripe"],
    image: "/images/p3d.png",
    link: "https://power3designs.com",
    userStory: "As a web development company, Power3Designs needed a modern, responsive website to showcase their services and attract potential clients. The website needed to effectively communicate their expertise, display their portfolio, and provide an easy way for clients to get in touch.",
    gallery: [
      "/images/p3d.png",
      "/images/p3d-portfolio.png",
      "/images/p3d-services.png"
    ],
    features: [
      "Responsive design that works seamlessly across all devices",
      "Interactive portfolio showcase",
      "Integrated contact form with email notifications",
      "Blog section for sharing industry insights",
      "Service pricing calculator"
    ],
    challenges: [
      "Creating a design that would effectively showcase both services and portfolio items",
      "Implementing a performant image gallery with lazy loading",
      "Ensuring fast page load times while maintaining visual quality"
    ],
    solutions: [
      "Implemented a modular design system for consistent styling",
      "Used Next.js Image component for optimal image loading",
      "Implemented caching strategies for improved performance"
    ]
  },
  {
    id: "client-management-system",
    title: "Client Management System for Power3Designs",
    description: "Platform for managing client information, interactions, and support requests. Kanban board for task management and progress tracking.",
    technologies: ["Vue.js", "GoLang", "Vuetify", "PostgreSQL"],
    image: "/images/appmobile3.png",
    userStory: "Power3Designs needed an internal system to manage client relationships, track project progress, and handle support requests efficiently. The system needed to provide real-time updates and facilitate collaboration between team members.",
    gallery: [
      "/images/appmobile3.png",
      "/images/cms-dashboard.png",
      "/images/cms-kanban.png"
    ]
  },
  {
    id: "healthcare-claims",
    title: "Healthcare Claims Management System",
    description: "Portal for healthcare providers to manage insurance claims and for patients to track their claims.",
    technologies: ["Experience Cloud", "Salesforce", "AWS", "Apex", "HIPAA Compliance"],
    image: "/images/nuevo-dashboard.jpg"
  },
  {
    id: "ecommerce-platform",
    title: "E-Commerce Platform",
    description: "Modern e-commerce solution with advanced product management, real-time inventory, and seamless payment integration.",
    technologies: ["Next.js", "TypeScript", "Stripe", "MongoDB"],
    image: "/images/ecommerce.jpg",
    link: "https://ecommerce-demo.power3designs.com"
  },
  {
    id: "healthcare-portal",
    title: "Healthcare Portal",
    description: "Patient management system with secure data handling, appointment scheduling, and electronic health records.",
    technologies: ["React", "Node.js", "Express", "PostgreSQL", "HIPAA Compliance"],
    image: "/images/healthcare.jpg"
  },
  {
    id: "ai-content-generator",
    title: "AI Content Generator",
    description: "Web application leveraging AI to generate and optimize content for various platforms and purposes.",
    technologies: ["Python", "FastAPI", "React", "OpenAI", "Docker"],
    image: "/images/ai-content.jpg"
  },
  {
    id: "real-estate-platform",
    title: "Real Estate Platform",
    description: "Property listing and management platform with virtual tours and interactive maps integration.",
    technologies: ["Vue.js", "Firebase", "Google Maps API", "Three.js"],
    image: "/images/real-estate.jpg"
  },
  {
    id: "social-media-analytics",
    title: "Social Media Analytics",
    description: "Dashboard for tracking and analyzing social media metrics across multiple platforms in real-time.",
    technologies: ["React", "D3.js", "Node.js", "Redis", "Social APIs"],
    image: "/images/analytics.jpg"
  }
]; 