import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faReact, 
  faVuejs, 
  faNode, 
  faJs, 
  faPython, 
  faGithub, 
  faStripe,
  faAws,
  faCss3Alt,
  faHtml5,
  faFigma  
} from '@fortawesome/free-brands-svg-icons';
import { 
  faBolt, 
  faCloud, 
  faCode,
  faDatabase,
  faPalette,
  faDownload
} from '@fortawesome/free-solid-svg-icons';

interface Experience {
  company: string;
  website?: string;
  title: string;
  period: string;
  responsibilities: string[];
}

interface Skill {
  name: string;
  icon: any;
}

const Resume: React.FC = () => {
  const skills: Skill[] = [
    { name: 'React', icon: faReact },
    { name: 'Next.js', icon: faReact },
    { name: 'Vue', icon: faVuejs },
    { name: 'Node', icon: faNode },
    { name: 'JavaScript', icon: faJs },
    { name: 'TypeScript', icon: faCode },
    { name: 'Python', icon: faPython },
    { name: 'HTML5', icon: faHtml5 },
    { name: 'CSS3/SASS', icon: faCss3Alt },
    { name: 'Tailwind', icon: faPalette },
    { name: 'Figma', icon: faFigma },
    { name: 'AWS', icon: faAws },
    { name: 'Salesforce', icon: faCloud },
    { name: 'APEX', icon: faBolt },
    { name: 'Stripe', icon: faStripe },
    { name: 'Git/GitHub', icon: faGithub },
    { name: 'REST', icon: faCode },
    { name: 'Contentful', icon: faDatabase },
    { name: 'Sanity', icon: faDatabase },
    { name: 'Strapi', icon: faDatabase }
  ];

  const experiences: Experience[] = [
    {
      company: "Power3Designs",
      website: "www.power3designs.com",
      title: "Principal Frontend Engineer & Full Stack Software Engineer",
      period: "September 2017 to Present",
      responsibilities: [
        "Orchestrated the UX/UI development lifecycle, ensuring the delivery of solutions that enhance user engagement and streamline navigation.",
        "Crafted a scalable design framework, standardizing UX processes and documentation across projects.",
        "Engineered comprehensive front-end solutions using React and Vue, integrating robust back-end systems.",
        "Masterminded the development and refinement of MVPs, turning user stories and UX research into products.",
        "Spearheaded the coding and design of customizable themes and templates.",
        "Directed multidisciplinary teams through agile development cycles.",
        "Fostered the professional growth of Junior Developers and Designers.",
        "Implemented rigorous QA protocols, ensuring cross-platform consistency.",
        "Initiated and led a transformative overhaul of the existing tech stack.",
      ]
    },
    {
      company: "Sedera",
      title: "Senior Software Engineer (Frontend Focused)",
      period: "Oct 2022 - Apr 2024",
      responsibilities: [
        "Spearheaded the design and development of an advanced claims management system.",
        "Led UX/UI design and implementation for multiple projects.",
        "Mentored junior developers and designers.",
        "Designed and implemented wireframes and prototypes using Figma.",
        "Overhauled data modeling architecture.",
        "Upgraded the React, Next.js, and Node.js tech stack.",
        "Conducted extensive quality assurance testing across web projects."
      ]
    },
    {
      company: "NuevoCloud, INC.",
      title: "Lead Designer/Front-End Developer",
      period: "2018",
      responsibilities: [
        "Led redesign of front-end web application",
        "Analyzed usage patterns to understand common user workflows.",
        "Developed UX/UI wireframes and mockups of design concepts",
        "Worked with dev team to ensure consistent application of new design guidelines.",
        "Simplified user interface leading to increased user satisfaction."
      ]
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-[52rem] mx-auto"
    >
      <motion.div
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2 }}
        className="bg-white rounded-xl shadow-lg overflow-hidden"
      >
        <div className="p-10 md:p-14">
          <motion.h1 
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-3xl font-bold text-gray-900 mb-6 flex justify-between items-center"
          >
            Resume
            <a 
              href="/images/Suzanne-Brenner-Senior-Frontend-Engineer.pdf" 
              className="text-sm inline-block text-white bg-[#638fd8] hover:bg-[#7B9AD2] px-6 py-2 rounded-lg transition-colors uppercase tracking-wider"
              download="Suzanne-Brenner-Senior-Frontend-Engineer.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Resume <FontAwesomeIcon icon={faDownload} />
            </a>
            
          </motion.h1>

          {/* Skills Section */}
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.3 }}
            className="mb-10"
          >
            <h3 className="text-2xl font-bold mb-6">Technical Skill Set</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {skills.map((skill, index) => (
                <div 
                  key={index}
                  className="bg-[#4b5563]/10 text-[#4b5563] rounded-lg p-3 flex items-center justify-center gap-2 font-medium shadow-sm hover:shadow-md transition-all duration-200"
                >
                  <FontAwesomeIcon 
                    icon={skill.icon} 
                    className="w-5 h-5" 
                  />
                  {skill.name}
                </div>
              ))}
            </div>
          </motion.div>

          {/* Experience Section */}
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <h3 className="text-2xl font-bold mb-6">Professional Experience</h3>
            <div className="space-y-8">
              {experiences.map((exp, index) => (
                <motion.div
                  key={index}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-50 p-6 rounded-lg shadow-sm"
                >
                  <div className="mb-4">
                    <h4 className="text-xl font-bold">
                      {exp.company}
                      {exp.website && (
                        <span className="text-sm font-normal text-gray-600 ml-2">
                          {exp.website}
                        </span>
                      )}
                    </h4>
                    <h5 className="text-lg font-semibold text-[#F52549]">{exp.title}</h5>
                    <p className="text-gray-600 italic">{exp.period}</p>
                  </div>
                  <ul className="list-disc list-inside space-y-2">
                    {exp.responsibilities.map((resp, idx) => (
                      <li key={idx} className="text-gray-700">{resp}</li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Resume;