interface Certification {
    title: string;
    issuer: string;
    date: string;
    imageUrl: string;
    verificationUrl?: string;
}

export const certifications: Certification[] = [
    {
        title: "Salesforce Certified User Experience Designer",
        issuer: "SalesForce",
        date: "Nov. 2023",
        imageUrl: "/images/certifications/sfcert.png",
        verificationUrl: "https://example.com/verify"
    },
    {
        title: "Google UX Design Professional Certificate",
        issuer: "Google",
        date: "Aug. 2023",
        imageUrl: "/images/certifications/GoogleUX.png",
        verificationUrl: "https://example.com/verify"
    }
];
