import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { projects } from '../data/projects';
import ImageModal from '../components/ImageModal';

const ProjectDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const project = projects.find(p => p.id === id);

    if (!project) {
        return (
            <div className="text-center py-20">
                <h2 className="text-2xl font-bold text-gray-900">Project not found</h2>
                <button 
                    onClick={() => navigate('/portfolio')}
                    className="mt-4 text-[#638fd8] hover:text-[#7B9AD2]"
                >
                    Back to Portfolio
                </button>
            </div>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="max-w-[52rem] mx-auto"
        >
            <motion.div
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-white rounded-xl shadow-lg overflow-hidden"
            >
                <div className="p-10 md:p-14">
                    {/* Back Button */}
                    <button 
                        onClick={() => navigate('/portfolio')}
                        className="mb-6 text-[#638fd8] hover:text-[#7B9AD2] flex items-center"
                    >
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                        Back to Portfolio
                    </button>

                    {/* Project Title */}
                    <motion.h1
                        initial={{ y: -20 }}
                        animate={{ y: 0 }}
                        transition={{ delay: 0.2 }}
                        className="text-4xl font-bold text-gray-900 mb-4"
                    >
                        {project.title}
                    </motion.h1>

                    {/* Project Description */}
                    <div className="prose max-w-none mb-8">
                        <p className="text-gray-600">{project.description}</p>
                    </div>

                    {/* Project Details */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                        <div>
                            <h2 className="text-xl font-bold text-gray-900 mb-4">Project Details</h2>
                            <div className="space-y-4">
                                <div>
                                    <h3 className="font-semibold text-gray-900">Technologies Used</h3>
                                    <div className="flex flex-wrap gap-2 mt-2">
                                        {project.technologies.map((tech, index) => (
                                            <span
                                                key={index}
                                                className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm"
                                            >
                                                {tech}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                {project.link && (
                                    <div>
                                        <h3 className="font-semibold text-gray-900">Project Link</h3>
                                        <a 
                                            href={project.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-[#638fd8] hover:text-[#7B9AD2]"
                                        >
                                            Visit Project
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <div>
                            <h2 className="text-xl font-bold text-gray-900 mb-4">User Story</h2>
                            <div className="prose max-w-none">
                                <p className="text-gray-600">{project.userStory || "No user story available."}</p>
                            </div>
                        </div>
                    </div>

                    {/* Project Gallery */}
                    {project.gallery && project.gallery.length > 0 && (
                        <div>
                            <h2 className="text-xl font-bold text-gray-900 mb-4">Project Gallery</h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {project.gallery.map((image, index) => (
                                    <div 
                                        key={index}
                                        className="relative aspect-video cursor-pointer overflow-hidden rounded-lg"
                                        onClick={() => setSelectedImage(image)}
                                    >
                                        <img 
                                            src={image} 
                                            alt={`${project.title} gallery ${index + 1}`}
                                            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </motion.div>

            {/* Image Modal */}
            <ImageModal
                isOpen={!!selectedImage}
                imageUrl={selectedImage || ''}
                onClose={() => setSelectedImage(null)}
            />
        </motion.div>
    );
};

export default ProjectDetails; 