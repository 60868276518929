import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';

interface PasswordProtectedProps {
  children: React.ReactNode;
  timeout?: number; // timeout in minutes
}

const PasswordProtected: React.FC<PasswordProtectedProps> = ({ 
  children, 
  timeout = 10 // default 10 minutes
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Get password from environment variable
  const password = process.env.REACT_APP_PORTFOLIO_PASSWORD;

  // Check if password is configured
  useEffect(() => {
    if (!password) {
      setError('Password not configured. Please contact the administrator.');
    }
  }, [password]);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    localStorage.removeItem('portfolio_authenticated');
    localStorage.removeItem('portfolio_last_activity');
  }, []);

  // Handle inactivity timeout
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      
      localStorage.setItem('portfolio_last_activity', Date.now().toString());
      timeoutId = setTimeout(() => {
        logout();
      }, timeout * 60 * 1000);
    };

    if (isAuthenticated) {
      const lastActivity = localStorage.getItem('portfolio_last_activity');
      const now = Date.now();
      
      if (lastActivity && (now - parseInt(lastActivity) > timeout * 60 * 1000)) {
        logout();
        return;
      }
      
      resetTimer();

      // Event listeners for user activity
      const handleActivity = () => {
        if (isAuthenticated) {
          resetTimer();
        }
      };

      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keypress', handleActivity);
      window.addEventListener('click', handleActivity);
      window.addEventListener('scroll', handleActivity);

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        window.removeEventListener('mousemove', handleActivity);
        window.removeEventListener('keypress', handleActivity);
        window.removeEventListener('click', handleActivity);
        window.removeEventListener('scroll', handleActivity);
      };
    }
  }, [isAuthenticated, timeout, logout]);

  // Check initial authentication
  useEffect(() => {
    const authenticated = localStorage.getItem('portfolio_authenticated');
    if (authenticated === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const cleanedInput = inputPassword.trim();
    
    if (!password) {
      setError('Password not configured. Please contact the administrator.');
      return;
    }

    if (cleanedInput === password) {
      setIsAuthenticated(true);
      localStorage.setItem('portfolio_authenticated', 'true');
      localStorage.setItem('portfolio_last_activity', Date.now().toString());
      setError('');
    } else {
      setError('Incorrect password. Please try again.');
      setInputPassword('');
    }
  };

  if (isAuthenticated) {
    return (
      <div className="relative">
        {children}
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-[60vh] flex items-center justify-center px-4"
    >
      <div className="max-w-md w-full bg-white rounded-xl shadow-lg flex flex-col overflow-hidden">
        <div className="p-8 pb-0">
          <h2 className="text-center text-3xl font-bold text-gray-900">
            Protected Content
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please enter the password to view portfolio
          </p>
        </div>
        <form className="p-8 flex-1 flex flex-col" onSubmit={handleSubmit}>
          <div className="flex-1 flex flex-col justify-center">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                required
                value={inputPassword}
                onChange={(e) => setInputPassword(e.target.value)}
                className="appearance-none rounded-lg relative block w-full px-3 py-2 pr-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Enter password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-3 z-10"
              >
                {showPassword ? (
                  <svg className="h-5 w-5 text-[#F52549] hover:text-[#d66880]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                ) : (
                  <svg className="h-5 w-5 text-[#F52549] hover:text-[#d66880]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                )}
              </button>
            </div>

            {error && (
              <p className="text-red-500 text-sm text-center mt-4">
                {error}
              </p>
            )}
          </div>

          <div className="mt-8 -mx-8 -mb-8">
            <button
              type="submit"
              className="w-full py-4 text-sm font-semibold text-white bg-[#638fd8] hover:bg-[#7B9AD2] focus:outline-none focus:ring-2 focus:ring-[#89ABE3] focus:ring-offset-2 transition-colors duration-200 uppercase tracking-wider"
            >
              Access Portfolio
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default PasswordProtected; 